import { FC } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { DateFilterOptions, DateFilterValue } from '../../../../models/TableViewFilters';
import { useTranslation } from 'react-i18next';
import { DateInterval, DateIntervalKeys } from '../../../../models/DateInterval';
import DateUtils from '../../../../utils/DateUtils';
import Badge from '../../../shared/badge/Badge';

const DateFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter } = props;
  const dateFilter = filter as DateFilterValue | undefined;
  const { t } = useTranslation(['table-view', 'common']);

  return (
    <div>
      {dateFilter && (
        <div className="flex items-center gap-2">
          {(dateFilter.selectedOption === DateFilterOptions.TODAY ||
            dateFilter.selectedOption === DateFilterOptions.OVERDUE ||
            dateFilter.selectedOption === DateFilterOptions.THIS_WEEK ||
            dateFilter.selectedOption === DateFilterOptions.NOT_SET) && (
            <Badge textClass="text-dpm-gray-2 !font-normal" text={t(`filters.date.${dateFilter.selectedOption}`)} />
          )}
          {dateFilter.selectedOption === DateFilterOptions.MORE_THAN && (
            <Badge
              textClass="text-dpm-gray-2 !font-normal"
              text={t('filters.date.combined-interval', {
                option: t(`filters.date.${dateFilter.selectedOption}`),
                value: dateFilter.moreThan?.value,
                interval: t(DateIntervalKeys[dateFilter?.moreThan?.interval as DateInterval]),
              })}
            />
          )}
          {dateFilter.selectedOption === DateFilterOptions.LESS_THAN && (
            <Badge
              textClass="text-dpm-gray-2 !font-normal"
              text={t('filters.date.combined-interval', {
                option: t(`filters.date.${dateFilter.selectedOption}`),
                value: dateFilter.lessThan?.value,
                interval: t(DateIntervalKeys[dateFilter?.lessThan?.interval as DateInterval]),
              })}
            />
          )}
          {dateFilter.selectedOption === DateFilterOptions.BETWEEN && (
            <>
              <Badge
                textClass="text-dpm-gray-2 !font-normal"
                text={t('filters.date.combined-between', {
                  option: t(`filters.date.${dateFilter.selectedOption}`),
                  start: DateUtils.formatDate(new Date(dateFilter.between?.start as string)),
                  end: DateUtils.formatDate(new Date(dateFilter.between?.end as string)),
                })}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DateFilterOverview;
